@charset "UTF-8";
@import url("//hello.myfonts.net/count/311b1f");
.pink-c, .text-primary {
  color: #d60069;
}

.text-light {
  color: #b2b2b2;
}

@keyframes fadeIn {
  from {
    opacity: 0.25;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.vp-barely-visible {
  opacity: 0.25;
}

.vp-hidden {
  opacity: 0;
}

.vp-visible {
  animation: 1s fadeIn forwards;
}

.vp-fadein {
  animation: 1s fadeInRight forwards;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.ta-c {
  text-align: center;
}

.ta-l {
  text-align: left;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.disable-pointer-events {
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .hide-for-s {
    display: none !important;
  }
}
@media only screen and (min-width:0px) and (max-width:1024px) {
  .hide-for-m-down {
    display: none !important;
  }
}
@media only screen and (min-width:768px) {
  .hide-for-m-up {
    display: none !important;
  }
}
@media only screen and (min-width:768px) and (max-width:1024px) {
  .hide-for-m {
    display: none !important;
  }
}
@media only screen and (min-width:1025px) {
  .hide-for-l {
    display: none !important;
  }
}
@keyframes scrolling {
  0% {
    opacity: 1;
    top: 18px;
  }
  50% {
    opacity: 0.5;
    top: 35px;
  }
  100% {
    opacity: 0;
    top: 47px;
  }
}
@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -10px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  90% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 10deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -6deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 3deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -3deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.3333333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: " ";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-ad {
  background-image: url(../../assets/flags/4x3/ad.svg);
}
.flag-icon-ad.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ad.svg);
}

.flag-icon-ae {
  background-image: url(../../assets/flags/4x3/ae.svg);
}
.flag-icon-ae.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ae.svg);
}

.flag-icon-af {
  background-image: url(../../assets/flags/4x3/af.svg);
}
.flag-icon-af.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/af.svg);
}

.flag-icon-ag {
  background-image: url(../../assets/flags/4x3/ag.svg);
}
.flag-icon-ag.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ag.svg);
}

.flag-icon-ai {
  background-image: url(../../assets/flags/4x3/ai.svg);
}
.flag-icon-ai.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ai.svg);
}

.flag-icon-al {
  background-image: url(../../assets/flags/4x3/al.svg);
}
.flag-icon-al.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/al.svg);
}

.flag-icon-am {
  background-image: url(../../assets/flags/4x3/am.svg);
}
.flag-icon-am.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/am.svg);
}

.flag-icon-ao {
  background-image: url(../../assets/flags/4x3/ao.svg);
}
.flag-icon-ao.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ao.svg);
}

.flag-icon-aq {
  background-image: url(../../assets/flags/4x3/aq.svg);
}
.flag-icon-aq.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/aq.svg);
}

.flag-icon-ar {
  background-image: url(../../assets/flags/4x3/ar.svg);
}
.flag-icon-ar.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ar.svg);
}

.flag-icon-as {
  background-image: url(../../assets/flags/4x3/as.svg);
}
.flag-icon-as.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/as.svg);
}

.flag-icon-at {
  background-image: url(../../assets/flags/4x3/at.svg);
}
.flag-icon-at.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/at.svg);
}

.flag-icon-au {
  background-image: url(../../assets/flags/4x3/au.svg);
}
.flag-icon-au.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/au.svg);
}

.flag-icon-aw {
  background-image: url(../../assets/flags/4x3/aw.svg);
}
.flag-icon-aw.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/aw.svg);
}

.flag-icon-ax {
  background-image: url(../../assets/flags/4x3/ax.svg);
}
.flag-icon-ax.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ax.svg);
}

.flag-icon-az {
  background-image: url(../../assets/flags/4x3/az.svg);
}
.flag-icon-az.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/az.svg);
}

.flag-icon-ba {
  background-image: url(../../assets/flags/4x3/ba.svg);
}
.flag-icon-ba.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ba.svg);
}

.flag-icon-bb {
  background-image: url(../../assets/flags/4x3/bb.svg);
}
.flag-icon-bb.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bb.svg);
}

.flag-icon-bd {
  background-image: url(../../assets/flags/4x3/bd.svg);
}
.flag-icon-bd.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bd.svg);
}

.flag-icon-be {
  background-image: url(../../assets/flags/4x3/be.svg);
}
.flag-icon-be.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/be.svg);
}

.flag-icon-bf {
  background-image: url(../../assets/flags/4x3/bf.svg);
}
.flag-icon-bf.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bf.svg);
}

.flag-icon-bg {
  background-image: url(../../assets/flags/4x3/bg.svg);
}
.flag-icon-bg.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bg.svg);
}

.flag-icon-bh {
  background-image: url(../../assets/flags/4x3/bh.svg);
}
.flag-icon-bh.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bh.svg);
}

.flag-icon-bi {
  background-image: url(../../assets/flags/4x3/bi.svg);
}
.flag-icon-bi.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bi.svg);
}

.flag-icon-bj {
  background-image: url(../../assets/flags/4x3/bj.svg);
}
.flag-icon-bj.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bj.svg);
}

.flag-icon-bl {
  background-image: url(../../assets/flags/4x3/bl.svg);
}
.flag-icon-bl.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bl.svg);
}

.flag-icon-bm {
  background-image: url(../../assets/flags/4x3/bm.svg);
}
.flag-icon-bm.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bm.svg);
}

.flag-icon-bn {
  background-image: url(../../assets/flags/4x3/bn.svg);
}
.flag-icon-bn.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bn.svg);
}

.flag-icon-bo {
  background-image: url(../../assets/flags/4x3/bo.svg);
}
.flag-icon-bo.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bo.svg);
}

.flag-icon-bq {
  background-image: url(../../assets/flags/4x3/bq.svg);
}
.flag-icon-bq.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bq.svg);
}

.flag-icon-br {
  background-image: url(../../assets/flags/4x3/br.svg);
}
.flag-icon-br.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/br.svg);
}

.flag-icon-bs {
  background-image: url(../../assets/flags/4x3/bs.svg);
}
.flag-icon-bs.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bs.svg);
}

.flag-icon-bt {
  background-image: url(../../assets/flags/4x3/bt.svg);
}
.flag-icon-bt.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bt.svg);
}

.flag-icon-bv {
  background-image: url(../../assets/flags/4x3/bv.svg);
}
.flag-icon-bv.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bv.svg);
}

.flag-icon-bw {
  background-image: url(../../assets/flags/4x3/bw.svg);
}
.flag-icon-bw.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bw.svg);
}

.flag-icon-by {
  background-image: url(../../assets/flags/4x3/by.svg);
}
.flag-icon-by.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/by.svg);
}

.flag-icon-bz {
  background-image: url(../../assets/flags/4x3/bz.svg);
}
.flag-icon-bz.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/bz.svg);
}

.flag-icon-ca {
  background-image: url(../../assets/flags/4x3/ca.svg);
}
.flag-icon-ca.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ca.svg);
}

.flag-icon-cc {
  background-image: url(../../assets/flags/4x3/cc.svg);
}
.flag-icon-cc.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cc.svg);
}

.flag-icon-cd {
  background-image: url(../../assets/flags/4x3/cd.svg);
}
.flag-icon-cd.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cd.svg);
}

.flag-icon-cf {
  background-image: url(../../assets/flags/4x3/cf.svg);
}
.flag-icon-cf.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cf.svg);
}

.flag-icon-cg {
  background-image: url(../../assets/flags/4x3/cg.svg);
}
.flag-icon-cg.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cg.svg);
}

.flag-icon-ch {
  background-image: url(../../assets/flags/4x3/ch.svg);
}
.flag-icon-ch.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ch.svg);
}

.flag-icon-ci {
  background-image: url(../../assets/flags/4x3/ci.svg);
}
.flag-icon-ci.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ci.svg);
}

.flag-icon-ck {
  background-image: url(../../assets/flags/4x3/ck.svg);
}
.flag-icon-ck.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ck.svg);
}

.flag-icon-cl {
  background-image: url(../../assets/flags/4x3/cl.svg);
}
.flag-icon-cl.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cl.svg);
}

.flag-icon-cm {
  background-image: url(../../assets/flags/4x3/cm.svg);
}
.flag-icon-cm.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cm.svg);
}

.flag-icon-cn {
  background-image: url(../../assets/flags/4x3/cn.svg);
}
.flag-icon-cn.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cn.svg);
}

.flag-icon-co {
  background-image: url(../../assets/flags/4x3/co.svg);
}
.flag-icon-co.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/co.svg);
}

.flag-icon-cr {
  background-image: url(../../assets/flags/4x3/cr.svg);
}
.flag-icon-cr.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cr.svg);
}

.flag-icon-cu {
  background-image: url(../../assets/flags/4x3/cu.svg);
}
.flag-icon-cu.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cu.svg);
}

.flag-icon-cv {
  background-image: url(../../assets/flags/4x3/cv.svg);
}
.flag-icon-cv.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cv.svg);
}

.flag-icon-cw {
  background-image: url(../../assets/flags/4x3/cw.svg);
}
.flag-icon-cw.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cw.svg);
}

.flag-icon-cx {
  background-image: url(../../assets/flags/4x3/cx.svg);
}
.flag-icon-cx.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cx.svg);
}

.flag-icon-cy {
  background-image: url(../../assets/flags/4x3/cy.svg);
}
.flag-icon-cy.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cy.svg);
}

.flag-icon-cz {
  background-image: url(../../assets/flags/4x3/cz.svg);
}
.flag-icon-cz.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/cz.svg);
}

.flag-icon-de {
  background-image: url(../../assets/flags/4x3/de.svg);
}
.flag-icon-de.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/de.svg);
}

.flag-icon-dj {
  background-image: url(../../assets/flags/4x3/dj.svg);
}
.flag-icon-dj.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/dj.svg);
}

.flag-icon-dk {
  background-image: url(../../assets/flags/4x3/dk.svg);
}
.flag-icon-dk.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/dk.svg);
}

.flag-icon-dm {
  background-image: url(../../assets/flags/4x3/dm.svg);
}
.flag-icon-dm.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/dm.svg);
}

.flag-icon-do {
  background-image: url(../../assets/flags/4x3/do.svg);
}
.flag-icon-do.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/do.svg);
}

.flag-icon-dz {
  background-image: url(../../assets/flags/4x3/dz.svg);
}
.flag-icon-dz.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/dz.svg);
}

.flag-icon-ec {
  background-image: url(../../assets/flags/4x3/ec.svg);
}
.flag-icon-ec.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ec.svg);
}

.flag-icon-ee {
  background-image: url(../../assets/flags/4x3/ee.svg);
}
.flag-icon-ee.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ee.svg);
}

.flag-icon-eg {
  background-image: url(../../assets/flags/4x3/eg.svg);
}
.flag-icon-eg.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/eg.svg);
}

.flag-icon-eh {
  background-image: url(../../assets/flags/4x3/eh.svg);
}
.flag-icon-eh.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/eh.svg);
}

.flag-icon-er {
  background-image: url(../../assets/flags/4x3/er.svg);
}
.flag-icon-er.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/er.svg);
}

.flag-icon-es {
  background-image: url(../../assets/flags/4x3/es.svg);
}
.flag-icon-es.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/es.svg);
}

.flag-icon-et {
  background-image: url(../../assets/flags/4x3/et.svg);
}
.flag-icon-et.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/et.svg);
}

.flag-icon-fi {
  background-image: url(../../assets/flags/4x3/fi.svg);
}
.flag-icon-fi.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/fi.svg);
}

.flag-icon-fj {
  background-image: url(../../assets/flags/4x3/fj.svg);
}
.flag-icon-fj.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/fj.svg);
}

.flag-icon-fk {
  background-image: url(../../assets/flags/4x3/fk.svg);
}
.flag-icon-fk.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/fk.svg);
}

.flag-icon-fm {
  background-image: url(../../assets/flags/4x3/fm.svg);
}
.flag-icon-fm.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/fm.svg);
}

.flag-icon-fo {
  background-image: url(../../assets/flags/4x3/fo.svg);
}
.flag-icon-fo.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/fo.svg);
}

.flag-icon-fr {
  background-image: url(../../assets/flags/4x3/fr.svg);
}
.flag-icon-fr.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/fr.svg);
}

.flag-icon-ga {
  background-image: url(../../assets/flags/4x3/ga.svg);
}
.flag-icon-ga.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ga.svg);
}

.flag-icon-gb {
  background-image: url(../../assets/flags/4x3/gb.svg);
}
.flag-icon-gb.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gb.svg);
}

.flag-icon-gd {
  background-image: url(../../assets/flags/4x3/gd.svg);
}
.flag-icon-gd.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gd.svg);
}

.flag-icon-ge {
  background-image: url(../../assets/flags/4x3/ge.svg);
}
.flag-icon-ge.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ge.svg);
}

.flag-icon-gf {
  background-image: url(../../assets/flags/4x3/gf.svg);
}
.flag-icon-gf.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gf.svg);
}

.flag-icon-gg {
  background-image: url(../../assets/flags/4x3/gg.svg);
}
.flag-icon-gg.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gg.svg);
}

.flag-icon-gh {
  background-image: url(../../assets/flags/4x3/gh.svg);
}
.flag-icon-gh.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gh.svg);
}

.flag-icon-gi {
  background-image: url(../../assets/flags/4x3/gi.svg);
}
.flag-icon-gi.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gi.svg);
}

.flag-icon-gl {
  background-image: url(../../assets/flags/4x3/gl.svg);
}
.flag-icon-gl.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gl.svg);
}

.flag-icon-gm {
  background-image: url(../../assets/flags/4x3/gm.svg);
}
.flag-icon-gm.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gm.svg);
}

.flag-icon-gn {
  background-image: url(../../assets/flags/4x3/gn.svg);
}
.flag-icon-gn.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gn.svg);
}

.flag-icon-gp {
  background-image: url(../../assets/flags/4x3/gp.svg);
}
.flag-icon-gp.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gp.svg);
}

.flag-icon-gq {
  background-image: url(../../assets/flags/4x3/gq.svg);
}
.flag-icon-gq.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gq.svg);
}

.flag-icon-gr {
  background-image: url(../../assets/flags/4x3/gr.svg);
}
.flag-icon-gr.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gr.svg);
}

.flag-icon-gs {
  background-image: url(../../assets/flags/4x3/gs.svg);
}
.flag-icon-gs.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gs.svg);
}

.flag-icon-gt {
  background-image: url(../../assets/flags/4x3/gt.svg);
}
.flag-icon-gt.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gt.svg);
}

.flag-icon-gu {
  background-image: url(../../assets/flags/4x3/gu.svg);
}
.flag-icon-gu.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gu.svg);
}

.flag-icon-gw {
  background-image: url(../../assets/flags/4x3/gw.svg);
}
.flag-icon-gw.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gw.svg);
}

.flag-icon-gy {
  background-image: url(../../assets/flags/4x3/gy.svg);
}
.flag-icon-gy.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gy.svg);
}

.flag-icon-hk {
  background-image: url(../../assets/flags/4x3/hk.svg);
}
.flag-icon-hk.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/hk.svg);
}

.flag-icon-hm {
  background-image: url(../../assets/flags/4x3/hm.svg);
}
.flag-icon-hm.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/hm.svg);
}

.flag-icon-hn {
  background-image: url(../../assets/flags/4x3/hn.svg);
}
.flag-icon-hn.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/hn.svg);
}

.flag-icon-hr {
  background-image: url(../../assets/flags/4x3/hr.svg);
}
.flag-icon-hr.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/hr.svg);
}

.flag-icon-ht {
  background-image: url(../../assets/flags/4x3/ht.svg);
}
.flag-icon-ht.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ht.svg);
}

.flag-icon-hu {
  background-image: url(../../assets/flags/4x3/hu.svg);
}
.flag-icon-hu.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/hu.svg);
}

.flag-icon-id {
  background-image: url(../../assets/flags/4x3/id.svg);
}
.flag-icon-id.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/id.svg);
}

.flag-icon-ie {
  background-image: url(../../assets/flags/4x3/ie.svg);
}
.flag-icon-ie.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ie.svg);
}

.flag-icon-il {
  background-image: url(../../assets/flags/4x3/il.svg);
}
.flag-icon-il.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/il.svg);
}

.flag-icon-im {
  background-image: url(../../assets/flags/4x3/im.svg);
}
.flag-icon-im.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/im.svg);
}

.flag-icon-in {
  background-image: url(../../assets/flags/4x3/in.svg);
}
.flag-icon-in.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/in.svg);
}

.flag-icon-io {
  background-image: url(../../assets/flags/4x3/io.svg);
}
.flag-icon-io.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/io.svg);
}

.flag-icon-iq {
  background-image: url(../../assets/flags/4x3/iq.svg);
}
.flag-icon-iq.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/iq.svg);
}

.flag-icon-ir {
  background-image: url(../../assets/flags/4x3/ir.svg);
}
.flag-icon-ir.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ir.svg);
}

.flag-icon-is {
  background-image: url(../../assets/flags/4x3/is.svg);
}
.flag-icon-is.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/is.svg);
}

.flag-icon-it {
  background-image: url(../../assets/flags/4x3/it.svg);
}
.flag-icon-it.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/it.svg);
}

.flag-icon-je {
  background-image: url(../../assets/flags/4x3/je.svg);
}
.flag-icon-je.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/je.svg);
}

.flag-icon-jm {
  background-image: url(../../assets/flags/4x3/jm.svg);
}
.flag-icon-jm.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/jm.svg);
}

.flag-icon-jo {
  background-image: url(../../assets/flags/4x3/jo.svg);
}
.flag-icon-jo.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/jo.svg);
}

.flag-icon-jp {
  background-image: url(../../assets/flags/4x3/jp.svg);
}
.flag-icon-jp.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/jp.svg);
}

.flag-icon-ke {
  background-image: url(../../assets/flags/4x3/ke.svg);
}
.flag-icon-ke.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ke.svg);
}

.flag-icon-kg {
  background-image: url(../../assets/flags/4x3/kg.svg);
}
.flag-icon-kg.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/kg.svg);
}

.flag-icon-kh {
  background-image: url(../../assets/flags/4x3/kh.svg);
}
.flag-icon-kh.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/kh.svg);
}

.flag-icon-ki {
  background-image: url(../../assets/flags/4x3/ki.svg);
}
.flag-icon-ki.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ki.svg);
}

.flag-icon-km {
  background-image: url(../../assets/flags/4x3/km.svg);
}
.flag-icon-km.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/km.svg);
}

.flag-icon-kn {
  background-image: url(../../assets/flags/4x3/kn.svg);
}
.flag-icon-kn.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/kn.svg);
}

.flag-icon-kp {
  background-image: url(../../assets/flags/4x3/kp.svg);
}
.flag-icon-kp.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/kp.svg);
}

.flag-icon-kr {
  background-image: url(../../assets/flags/4x3/kr.svg);
}
.flag-icon-kr.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/kr.svg);
}

.flag-icon-kw {
  background-image: url(../../assets/flags/4x3/kw.svg);
}
.flag-icon-kw.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/kw.svg);
}

.flag-icon-ky {
  background-image: url(../../assets/flags/4x3/ky.svg);
}
.flag-icon-ky.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ky.svg);
}

.flag-icon-kz {
  background-image: url(../../assets/flags/4x3/kz.svg);
}
.flag-icon-kz.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/kz.svg);
}

.flag-icon-la {
  background-image: url(../../assets/flags/4x3/la.svg);
}
.flag-icon-la.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/la.svg);
}

.flag-icon-lb {
  background-image: url(../../assets/flags/4x3/lb.svg);
}
.flag-icon-lb.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/lb.svg);
}

.flag-icon-lc {
  background-image: url(../../assets/flags/4x3/lc.svg);
}
.flag-icon-lc.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/lc.svg);
}

.flag-icon-li {
  background-image: url(../../assets/flags/4x3/li.svg);
}
.flag-icon-li.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/li.svg);
}

.flag-icon-lk {
  background-image: url(../../assets/flags/4x3/lk.svg);
}
.flag-icon-lk.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/lk.svg);
}

.flag-icon-lr {
  background-image: url(../../assets/flags/4x3/lr.svg);
}
.flag-icon-lr.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/lr.svg);
}

.flag-icon-ls {
  background-image: url(../../assets/flags/4x3/ls.svg);
}
.flag-icon-ls.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ls.svg);
}

.flag-icon-lt {
  background-image: url(../../assets/flags/4x3/lt.svg);
}
.flag-icon-lt.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/lt.svg);
}

.flag-icon-lu {
  background-image: url(../../assets/flags/4x3/lu.svg);
}
.flag-icon-lu.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/lu.svg);
}

.flag-icon-lv {
  background-image: url(../../assets/flags/4x3/lv.svg);
}
.flag-icon-lv.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/lv.svg);
}

.flag-icon-ly {
  background-image: url(../../assets/flags/4x3/ly.svg);
}
.flag-icon-ly.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ly.svg);
}

.flag-icon-ma {
  background-image: url(../../assets/flags/4x3/ma.svg);
}
.flag-icon-ma.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ma.svg);
}

.flag-icon-mc {
  background-image: url(../../assets/flags/4x3/mc.svg);
}
.flag-icon-mc.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mc.svg);
}

.flag-icon-md {
  background-image: url(../../assets/flags/4x3/md.svg);
}
.flag-icon-md.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/md.svg);
}

.flag-icon-me {
  background-image: url(../../assets/flags/4x3/me.svg);
}
.flag-icon-me.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/me.svg);
}

.flag-icon-mf {
  background-image: url(../../assets/flags/4x3/mf.svg);
}
.flag-icon-mf.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mf.svg);
}

.flag-icon-mg {
  background-image: url(../../assets/flags/4x3/mg.svg);
}
.flag-icon-mg.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mg.svg);
}

.flag-icon-mh {
  background-image: url(../../assets/flags/4x3/mh.svg);
}
.flag-icon-mh.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mh.svg);
}

.flag-icon-mk {
  background-image: url(../../assets/flags/4x3/mk.svg);
}
.flag-icon-mk.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mk.svg);
}

.flag-icon-ml {
  background-image: url(../../assets/flags/4x3/ml.svg);
}
.flag-icon-ml.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ml.svg);
}

.flag-icon-mm {
  background-image: url(../../assets/flags/4x3/mm.svg);
}
.flag-icon-mm.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mm.svg);
}

.flag-icon-mn {
  background-image: url(../../assets/flags/4x3/mn.svg);
}
.flag-icon-mn.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mn.svg);
}

.flag-icon-mo {
  background-image: url(../../assets/flags/4x3/mo.svg);
}
.flag-icon-mo.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mo.svg);
}

.flag-icon-mp {
  background-image: url(../../assets/flags/4x3/mp.svg);
}
.flag-icon-mp.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mp.svg);
}

.flag-icon-mq {
  background-image: url(../../assets/flags/4x3/mq.svg);
}
.flag-icon-mq.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mq.svg);
}

.flag-icon-mr {
  background-image: url(../../assets/flags/4x3/mr.svg);
}
.flag-icon-mr.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mr.svg);
}

.flag-icon-ms {
  background-image: url(../../assets/flags/4x3/ms.svg);
}
.flag-icon-ms.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ms.svg);
}

.flag-icon-mt {
  background-image: url(../../assets/flags/4x3/mt.svg);
}
.flag-icon-mt.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mt.svg);
}

.flag-icon-mu {
  background-image: url(../../assets/flags/4x3/mu.svg);
}
.flag-icon-mu.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mu.svg);
}

.flag-icon-mv {
  background-image: url(../../assets/flags/4x3/mv.svg);
}
.flag-icon-mv.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mv.svg);
}

.flag-icon-mw {
  background-image: url(../../assets/flags/4x3/mw.svg);
}
.flag-icon-mw.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mw.svg);
}

.flag-icon-mx {
  background-image: url(../../assets/flags/4x3/mx.svg);
}
.flag-icon-mx.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mx.svg);
}

.flag-icon-my {
  background-image: url(../../assets/flags/4x3/my.svg);
}
.flag-icon-my.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/my.svg);
}

.flag-icon-mz {
  background-image: url(../../assets/flags/4x3/mz.svg);
}
.flag-icon-mz.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/mz.svg);
}

.flag-icon-na {
  background-image: url(../../assets/flags/4x3/na.svg);
}
.flag-icon-na.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/na.svg);
}

.flag-icon-nc {
  background-image: url(../../assets/flags/4x3/nc.svg);
}
.flag-icon-nc.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/nc.svg);
}

.flag-icon-ne {
  background-image: url(../../assets/flags/4x3/ne.svg);
}
.flag-icon-ne.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ne.svg);
}

.flag-icon-nf {
  background-image: url(../../assets/flags/4x3/nf.svg);
}
.flag-icon-nf.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/nf.svg);
}

.flag-icon-ng {
  background-image: url(../../assets/flags/4x3/ng.svg);
}
.flag-icon-ng.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ng.svg);
}

.flag-icon-ni {
  background-image: url(../../assets/flags/4x3/ni.svg);
}
.flag-icon-ni.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ni.svg);
}

.flag-icon-nl {
  background-image: url(../../assets/flags/4x3/nl.svg);
}
.flag-icon-nl.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/nl.svg);
}

.flag-icon-no {
  background-image: url(../../assets/flags/4x3/no.svg);
}
.flag-icon-no.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/no.svg);
}

.flag-icon-np {
  background-image: url(../../assets/flags/4x3/np.svg);
}
.flag-icon-np.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/np.svg);
}

.flag-icon-nr {
  background-image: url(../../assets/flags/4x3/nr.svg);
}
.flag-icon-nr.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/nr.svg);
}

.flag-icon-nu {
  background-image: url(../../assets/flags/4x3/nu.svg);
}
.flag-icon-nu.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/nu.svg);
}

.flag-icon-nz {
  background-image: url(../../assets/flags/4x3/nz.svg);
}
.flag-icon-nz.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/nz.svg);
}

.flag-icon-om {
  background-image: url(../../assets/flags/4x3/om.svg);
}
.flag-icon-om.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/om.svg);
}

.flag-icon-pa {
  background-image: url(../../assets/flags/4x3/pa.svg);
}
.flag-icon-pa.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/pa.svg);
}

.flag-icon-pe {
  background-image: url(../../assets/flags/4x3/pe.svg);
}
.flag-icon-pe.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/pe.svg);
}

.flag-icon-pf {
  background-image: url(../../assets/flags/4x3/pf.svg);
}
.flag-icon-pf.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/pf.svg);
}

.flag-icon-pg {
  background-image: url(../../assets/flags/4x3/pg.svg);
}
.flag-icon-pg.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/pg.svg);
}

.flag-icon-ph {
  background-image: url(../../assets/flags/4x3/ph.svg);
}
.flag-icon-ph.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ph.svg);
}

.flag-icon-pk {
  background-image: url(../../assets/flags/4x3/pk.svg);
}
.flag-icon-pk.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/pk.svg);
}

.flag-icon-pl {
  background-image: url(../../assets/flags/4x3/pl.svg);
}
.flag-icon-pl.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/pl.svg);
}

.flag-icon-pm {
  background-image: url(../../assets/flags/4x3/pm.svg);
}
.flag-icon-pm.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/pm.svg);
}

.flag-icon-pn {
  background-image: url(../../assets/flags/4x3/pn.svg);
}
.flag-icon-pn.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/pn.svg);
}

.flag-icon-pr {
  background-image: url(../../assets/flags/4x3/pr.svg);
}
.flag-icon-pr.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/pr.svg);
}

.flag-icon-ps {
  background-image: url(../../assets/flags/4x3/ps.svg);
}
.flag-icon-ps.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ps.svg);
}

.flag-icon-pt {
  background-image: url(../../assets/flags/4x3/pt.svg);
}
.flag-icon-pt.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/pt.svg);
}

.flag-icon-pw {
  background-image: url(../../assets/flags/4x3/pw.svg);
}
.flag-icon-pw.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/pw.svg);
}

.flag-icon-py {
  background-image: url(../../assets/flags/4x3/py.svg);
}
.flag-icon-py.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/py.svg);
}

.flag-icon-qa {
  background-image: url(../../assets/flags/4x3/qa.svg);
}
.flag-icon-qa.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/qa.svg);
}

.flag-icon-re {
  background-image: url(../../assets/flags/4x3/re.svg);
}
.flag-icon-re.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/re.svg);
}

.flag-icon-ro {
  background-image: url(../../assets/flags/4x3/ro.svg);
}
.flag-icon-ro.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ro.svg);
}

.flag-icon-rs {
  background-image: url(../../assets/flags/4x3/rs.svg);
}
.flag-icon-rs.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/rs.svg);
}

.flag-icon-ru {
  background-image: url(../../assets/flags/4x3/ru.svg);
}
.flag-icon-ru.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ru.svg);
}

.flag-icon-rw {
  background-image: url(../../assets/flags/4x3/rw.svg);
}
.flag-icon-rw.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/rw.svg);
}

.flag-icon-sa {
  background-image: url(../../assets/flags/4x3/sa.svg);
}
.flag-icon-sa.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sa.svg);
}

.flag-icon-sb {
  background-image: url(../../assets/flags/4x3/sb.svg);
}
.flag-icon-sb.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sb.svg);
}

.flag-icon-sc {
  background-image: url(../../assets/flags/4x3/sc.svg);
}
.flag-icon-sc.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sc.svg);
}

.flag-icon-sd {
  background-image: url(../../assets/flags/4x3/sd.svg);
}
.flag-icon-sd.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sd.svg);
}

.flag-icon-se {
  background-image: url(../../assets/flags/4x3/se.svg);
}
.flag-icon-se.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/se.svg);
}

.flag-icon-sg {
  background-image: url(../../assets/flags/4x3/sg.svg);
}
.flag-icon-sg.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sg.svg);
}

.flag-icon-sh {
  background-image: url(../../assets/flags/4x3/sh.svg);
}
.flag-icon-sh.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sh.svg);
}

.flag-icon-si {
  background-image: url(../../assets/flags/4x3/si.svg);
}
.flag-icon-si.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/si.svg);
}

.flag-icon-sj {
  background-image: url(../../assets/flags/4x3/sj.svg);
}
.flag-icon-sj.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sj.svg);
}

.flag-icon-sk {
  background-image: url(../../assets/flags/4x3/sk.svg);
}
.flag-icon-sk.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sk.svg);
}

.flag-icon-sl {
  background-image: url(../../assets/flags/4x3/sl.svg);
}
.flag-icon-sl.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sl.svg);
}

.flag-icon-sm {
  background-image: url(../../assets/flags/4x3/sm.svg);
}
.flag-icon-sm.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sm.svg);
}

.flag-icon-sn {
  background-image: url(../../assets/flags/4x3/sn.svg);
}
.flag-icon-sn.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sn.svg);
}

.flag-icon-so {
  background-image: url(../../assets/flags/4x3/so.svg);
}
.flag-icon-so.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/so.svg);
}

.flag-icon-sr {
  background-image: url(../../assets/flags/4x3/sr.svg);
}
.flag-icon-sr.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sr.svg);
}

.flag-icon-ss {
  background-image: url(../../assets/flags/4x3/ss.svg);
}
.flag-icon-ss.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ss.svg);
}

.flag-icon-st {
  background-image: url(../../assets/flags/4x3/st.svg);
}
.flag-icon-st.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/st.svg);
}

.flag-icon-sv {
  background-image: url(../../assets/flags/4x3/sv.svg);
}
.flag-icon-sv.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sv.svg);
}

.flag-icon-sx {
  background-image: url(../../assets/flags/4x3/sx.svg);
}
.flag-icon-sx.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sx.svg);
}

.flag-icon-sy {
  background-image: url(../../assets/flags/4x3/sy.svg);
}
.flag-icon-sy.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sy.svg);
}

.flag-icon-sz {
  background-image: url(../../assets/flags/4x3/sz.svg);
}
.flag-icon-sz.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/sz.svg);
}

.flag-icon-tc {
  background-image: url(../../assets/flags/4x3/tc.svg);
}
.flag-icon-tc.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/tc.svg);
}

.flag-icon-td {
  background-image: url(../../assets/flags/4x3/td.svg);
}
.flag-icon-td.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/td.svg);
}

.flag-icon-tf {
  background-image: url(../../assets/flags/4x3/tf.svg);
}
.flag-icon-tf.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/tf.svg);
}

.flag-icon-tg {
  background-image: url(../../assets/flags/4x3/tg.svg);
}
.flag-icon-tg.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/tg.svg);
}

.flag-icon-th {
  background-image: url(../../assets/flags/4x3/th.svg);
}
.flag-icon-th.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/th.svg);
}

.flag-icon-tj {
  background-image: url(../../assets/flags/4x3/tj.svg);
}
.flag-icon-tj.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/tj.svg);
}

.flag-icon-tk {
  background-image: url(../../assets/flags/4x3/tk.svg);
}
.flag-icon-tk.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/tk.svg);
}

.flag-icon-tl {
  background-image: url(../../assets/flags/4x3/tl.svg);
}
.flag-icon-tl.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/tl.svg);
}

.flag-icon-tm {
  background-image: url(../../assets/flags/4x3/tm.svg);
}
.flag-icon-tm.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/tm.svg);
}

.flag-icon-tn {
  background-image: url(../../assets/flags/4x3/tn.svg);
}
.flag-icon-tn.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/tn.svg);
}

.flag-icon-to {
  background-image: url(../../assets/flags/4x3/to.svg);
}
.flag-icon-to.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/to.svg);
}

.flag-icon-tr {
  background-image: url(../../assets/flags/4x3/tr.svg);
}
.flag-icon-tr.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/tr.svg);
}

.flag-icon-tt {
  background-image: url(../../assets/flags/4x3/tt.svg);
}
.flag-icon-tt.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/tt.svg);
}

.flag-icon-tv {
  background-image: url(../../assets/flags/4x3/tv.svg);
}
.flag-icon-tv.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/tv.svg);
}

.flag-icon-tw {
  background-image: url(../../assets/flags/4x3/tw.svg);
}
.flag-icon-tw.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/tw.svg);
}

.flag-icon-tz {
  background-image: url(../../assets/flags/4x3/tz.svg);
}
.flag-icon-tz.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/tz.svg);
}

.flag-icon-ua {
  background-image: url(../../assets/flags/4x3/ua.svg);
}
.flag-icon-ua.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ua.svg);
}

.flag-icon-ug {
  background-image: url(../../assets/flags/4x3/ug.svg);
}
.flag-icon-ug.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ug.svg);
}

.flag-icon-um {
  background-image: url(../../assets/flags/4x3/um.svg);
}
.flag-icon-um.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/um.svg);
}

.flag-icon-us {
  background-image: url(../../assets/flags/4x3/us.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/us.svg);
}

.flag-icon-uy {
  background-image: url(../../assets/flags/4x3/uy.svg);
}
.flag-icon-uy.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/uy.svg);
}

.flag-icon-uz {
  background-image: url(../../assets/flags/4x3/uz.svg);
}
.flag-icon-uz.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/uz.svg);
}

.flag-icon-va {
  background-image: url(../../assets/flags/4x3/va.svg);
}
.flag-icon-va.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/va.svg);
}

.flag-icon-vc {
  background-image: url(../../assets/flags/4x3/vc.svg);
}
.flag-icon-vc.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/vc.svg);
}

.flag-icon-ve {
  background-image: url(../../assets/flags/4x3/ve.svg);
}
.flag-icon-ve.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ve.svg);
}

.flag-icon-vg {
  background-image: url(../../assets/flags/4x3/vg.svg);
}
.flag-icon-vg.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/vg.svg);
}

.flag-icon-vi {
  background-image: url(../../assets/flags/4x3/vi.svg);
}
.flag-icon-vi.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/vi.svg);
}

.flag-icon-vn {
  background-image: url(../../assets/flags/4x3/vn.svg);
}
.flag-icon-vn.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/vn.svg);
}

.flag-icon-vu {
  background-image: url(../../assets/flags/4x3/vu.svg);
}
.flag-icon-vu.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/vu.svg);
}

.flag-icon-wf {
  background-image: url(../../assets/flags/4x3/wf.svg);
}
.flag-icon-wf.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/wf.svg);
}

.flag-icon-ws {
  background-image: url(../../assets/flags/4x3/ws.svg);
}
.flag-icon-ws.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ws.svg);
}

.flag-icon-ye {
  background-image: url(../../assets/flags/4x3/ye.svg);
}
.flag-icon-ye.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/ye.svg);
}

.flag-icon-yt {
  background-image: url(../../assets/flags/4x3/yt.svg);
}
.flag-icon-yt.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/yt.svg);
}

.flag-icon-za {
  background-image: url(../../assets/flags/4x3/za.svg);
}
.flag-icon-za.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/za.svg);
}

.flag-icon-zm {
  background-image: url(../../assets/flags/4x3/zm.svg);
}
.flag-icon-zm.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/zm.svg);
}

.flag-icon-zw {
  background-image: url(../../assets/flags/4x3/zw.svg);
}
.flag-icon-zw.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/zw.svg);
}

.flag-icon-es-ca {
  background-image: url(../../assets/flags/4x3/es-ca.svg);
}
.flag-icon-es-ca.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/es-ca.svg);
}

.flag-icon-es-ga {
  background-image: url(../../assets/flags/4x3/es-ga.svg);
}
.flag-icon-es-ga.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/es-ga.svg);
}

.flag-icon-eu {
  background-image: url(../../assets/flags/4x3/eu.svg);
}
.flag-icon-eu.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/eu.svg);
}

.flag-icon-gb-eng {
  background-image: url(../../assets/flags/4x3/gb-eng.svg);
}
.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gb-eng.svg);
}

.flag-icon-gb-nir {
  background-image: url(../../assets/flags/4x3/gb-nir.svg);
}
.flag-icon-gb-nir.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gb-nir.svg);
}

.flag-icon-gb-sct {
  background-image: url(../../assets/flags/4x3/gb-sct.svg);
}
.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gb-sct.svg);
}

.flag-icon-gb-wls {
  background-image: url(../../assets/flags/4x3/gb-wls.svg);
}
.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/gb-wls.svg);
}

.flag-icon-un {
  background-image: url(../../assets/flags/4x3/un.svg);
}
.flag-icon-un.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/un.svg);
}

.flag-icon-xk {
  background-image: url(../../assets/flags/4x3/xk.svg);
}
.flag-icon-xk.flag-icon-squared {
  background-image: url(../../assets/flags/1x1/xk.svg);
}

/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Remove default margin.
 */
body {
  margin: 0;
}

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none;
}

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic;
}

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ========================================================================== */
/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0;
}

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
*:before, *:after {
  box-sizing: border-box;
}

html {
  background: #fff;
}
@media only screen and (min-width: 1921px) {
  html {
    background-image: url("/typo3conf/ext/cbf_template/Resources/Public/Frontend/assets/misc/gradient-bg.png");
    background-repeat: repeat-y;
    background-position: center;
  }
}

body {
  max-width: 1920px;
  background-color: #fff;
  color: #000;
  overflow-x: hidden;
  overflow-y: visible;
  margin: 0 auto;
}
body ::selection {
  background-color: #d60069;
  color: #000;
}

.section {
  padding: 6rem 0;
}
.section--simple {
  text-align: center;
}
.section--simple p {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
}
.section--simple p:not(.section__headline) {
  margin-left: auto;
  margin-right: auto;
}
.section--simple h3.section__headline {
  font-size: 2.5rem;
}
.section--white {
  text-align: center;
  position: relative;
}
.section--white:after {
  content: "";
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 1px;
  max-width: 1300px;
  background-color: #000;
  border: none;
  margin: 0 auto;
}
.section--white p {
  position: relative;
  margin: 0 auto;
}
.section--white .section__headline {
  font-size: 2.5rem;
}
.section--white.no-divider:after {
  content: none;
}
.section--no-space {
  padding: 0;
}
.section--no-bottom-space {
  padding-bottom: 0;
}
.section__headline {
  font-family: "GeogrotesqueXComp-SmBd", normal;
  text-transform: uppercase;
  text-align: center;
  font-size: 2.75rem;
  line-height: 0.95;
  letter-spacing: 2px;
  margin-bottom: 38px;
}
@media only screen and (min-width:768px) {
  .section__headline {
    font-size: 3.85rem;
  }
}
.section ol {
  max-width: 1050px;
  margin: 0 auto;
  line-height: 1.5;
  text-align: left;
}
.section ol li:not(:last-of-type) {
  margin-bottom: 19px;
}

.section--white + .section--white {
  position: relative;
}
.section--white + .section--white:before {
  content: "";
  top: 0;
  right: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 1px;
  max-width: 1300px;
  background-color: #000;
  border: none;
  margin: 0 auto;
}

.gmaps {
  height: 300px;
}
@media only screen and (min-width:768px) {
  .gmaps {
    height: 600px;
  }
}
.gmaps iframe {
  width: 100%;
  height: 100%;
}

main p {
  font-size: 1.3rem;
  line-height: 1.5;
}
main p.centered {
  position: relative;
  margin: 0 auto;
  text-align: center;
}
main p:not(.section__headline) {
  max-width: 1050px;
}
main img[src$=".svg"] {
  height: 0;
}
main img:not([src$=".svg"]) {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
}
a:not([class]) {
  color: #d60069;
}

button {
  appearance: none;
  background: none;
  border: 0;
}

img {
  vertical-align: text-bottom;
}

ul, dl {
  line-height: 1.5;
  list-style-type: none;
}
ul:after, dl:after {
  content: "";
  display: block;
  clear: both;
}

/*
hr {
    max-width: $row-width;
    width: 100%;
    height: 1px;
    background-color: $black;
    border: none;
    margin: $base-spacing auto;
}
*/
figure figcaption {
  margin-top: 1rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
table td, table th {
  padding: 0;
}

.lang-menu {
  position: sticky;
  bottom: 0;
  left: 0;
  margin-top: -2.5rem;
  z-index: 1090;
  width: 2.5rem;
}
@media only screen and (min-width: 1974px) {
  .lang-menu {
    position: fixed;
    margin-top: 0;
  }
}
.lang-menu--inner {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
}
.lang-menu__current {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: #000;
  cursor: pointer;
  transition: 0.3s;
}
.lang-menu__current:hover {
  background-color: #404040;
}
.lang-menu .flag-icon {
  font-size: 1.5rem;
  border: 1px solid #404040;
}
.lang-menu__list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  transform: translateY(-100%);
  background-color: #000;
  text-align: center;
  display: none;
}
.lang-menu__list li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  transition: 0.3s;
  background-color: transparent;
}
.lang-menu__list li a:hover {
  background-color: #404040;
}

html {
  font-size: 14px;
}
@media only screen and (min-width:768px) {
  html {
    font-size: 16px;
  }
}
@media only screen and (min-width:1025px) {
  html {
    font-size: 18px;
  }
}

body {
  font-family: "Geogrotesque-Light", normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h3 {
  font-family: "Geogrotesque-SemiBold", normal;
  font-weight: normal;
  line-height: 0.95;
  color: #000;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 2.286rem;
}

h3 {
  font-size: 1.786rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 1.286rem;
}

h6 {
  font-size: 1rem;
}

p {
  line-height: 1.5;
}

strong {
  font-family: "Geogrotesque-SemiBold", normal;
  font-weight: 100;
}

p.text-center {
  text-align: center;
  margin: 0 auto;
}

input,
select,
button,
textarea {
  font-family: "Geogrotesque-Light", normal;
  font-size: 1rem;
}
input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: 0;
}

input[type=submit], label {
  cursor: pointer;
}

input {
  border: 0;
}

.browse-happy {
  position: relative;
  z-index: 50;
  font-size: 16px;
  text-align: center;
  background-color: white;
  border-bottom: 5px solid #5ec4e7;
  padding: 25px 12px;
}
.browse-happy__headline {
  font-size: 1.625em;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.browse-happy__browsers {
  margin-bottom: 15px;
}
.browse-happy__txt {
  line-height: 1.75;
  margin-bottom: 15px;
}
.browse-happy__btn {
  display: inline-block;
  font-weight: bold;
  background-color: #5ec4e7;
  color: white;
  border-radius: 4px;
  transition: background-color 0.15s;
  padding: 11px 20px;
}
.browse-happy__btn:hover {
  background-color: black;
  color: white;
}

[class*=row--] {
  width: 100%;
  max-width: 1338px;
  margin-left: auto;
  margin-right: auto;
}
[class*=row--]:after {
  content: "";
  display: block;
  clear: both;
}
[class*=row--] > [class*=row--] {
  width: auto;
  max-width: none;
  margin-left: -19px;
  margin-right: -19px;
}

.row--simple {
  padding: 0 19px;
}
@media only screen and (min-width:768px) {
  .row--2-col-grid > .col, .row--3-col-grid > .col, .row--4-col-grid > .col {
    width: 50%;
  }
  .row--5-col-grid > .col, .row--6-col-grid > .col {
    width: 33.3333333333%;
  }
}
@media only screen and (min-width:1025px) {
  .row--3-col-grid > .col {
    width: 33.3333333333%;
  }
  .row--4-col-grid > .col {
    width: 25%;
  }
  .row--5-col-grid > .col {
    width: 20%;
  }
  .row--6-col-grid > .col {
    width: 16.6666666667%;
  }
}

.col {
  width: 100%;
  float: left;
  padding: 0 19px;
  margin-bottom: 2rem;
}
.col--single {
  width: 100% !important;
}

.main-header {
  font-size: 1.25rem;
  letter-spacing: 2px;
  line-height: 1;
  font-family: "Geogrotesque-SemiBold", normal;
  text-transform: uppercase;
}
.main-header .mobile-menu {
  position: relative;
  z-index: 15;
  width: 100%;
  max-width: 1920px;
  padding: 19px;
  margin: 0 auto;
}
.main-header .mobile-menu:after {
  content: "";
  display: block;
  clear: both;
}
@media only screen and (min-width:768px) {
  .main-header .mobile-menu {
    padding: 0;
  }
}
@media only screen and (min-width:1025px) {
  .main-header .mobile-menu {
    display: none;
  }
}
.main-header .mobile-menu__logo {
  display: block;
  width: 303px;
  height: 135px;
  margin: 0 auto 19px auto;
  overflow: hidden;
}
@media only screen and (min-width:768px) {
  .main-header .mobile-menu__logo {
    top: 38px;
    left: 76px;
    position: absolute;
  }
}
.main-header .mobile-menu__logo img {
  width: auto;
  height: 135px;
}
.main-header .mobile-menu__nav {
  position: relative;
  cursor: pointer;
  z-index: 20;
  transition: background 0.5s;
}
.main-header .mobile-menu__nav:hover {
  background: #d2d2d2;
}
.main-header .mobile-menu__nav:hover .menu {
  max-height: 9999px;
  transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
  transition-delay: 0s;
}
@media only screen and (min-width:768px) {
  .main-header .mobile-menu__nav {
    top: 76px;
    right: 76px;
    position: absolute;
    max-width: 300px;
    width: 100%;
  }
}
.main-header .mobile-menu__nav .current-site {
  padding: 14px 20px 10px 20px;
  border-bottom: 1px solid #000;
}
.main-header .mobile-menu__nav .current-site:after {
  content: "";
  top: 13px;
  right: 20px;
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  background: url("/typo3conf/ext/cbf_template/Resources/Public/Frontend/assets/icon/misc/plus.svg") no-repeat center/15px;
  transition: transform 0.4s;
}
.main-header .mobile-menu__nav .current-site:hover:after {
  transform: rotate(45deg);
}
.main-header .mobile-menu__nav .menu {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.8s cubic-bezier(0, 1, 0, 1) -0.1s;
}
.main-header .mobile-menu__nav .menu ul li {
  border-bottom: 1px solid #000;
}
.main-header .mobile-menu__nav .menu ul li:hover {
  background: rgba(0, 0, 0, 0.5);
}
.main-header .mobile-menu__nav .menu ul li a {
  display: block;
  padding: 14px 20px 10px 20px;
  line-height: 1;
  color: #000;
}
.main-header .menu-desktop {
  display: none;
  position: fixed;
  width: 100%;
  max-width: 1920px;
  z-index: 2010;
  padding: 38px 38px 0 38px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  background-color: transparent;
  transition: 0.3s;
}
@media only screen and (min-width:1025px) {
  .main-header .menu-desktop {
    display: flex;
  }
}
@media only screen and (min-width: 1260px) {
  .main-header .menu-desktop {
    padding: 38px 76px 0 76px;
  }
}
.main-header .menu-desktop__logo {
  transition: 0.3s;
  display: block;
  position: relative;
  height: 120px;
  width: 100%;
  max-width: 270px;
  overflow: hidden;
}
@media only screen and (min-width: 1260px) {
  .main-header .menu-desktop__logo {
    height: 135px;
    max-width: 304px;
  }
}
.main-header .menu-desktop__logo img {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
}
.main-header .menu-desktop__nav {
  display: flex;
  align-items: center;
}
.main-header .menu-desktop__nav ul li {
  display: inline;
  padding-left: 19px;
}
@media only screen and (min-width: 1260px) {
  .main-header .menu-desktop__nav ul li {
    padding-left: 38px;
  }
}
.main-header .menu-desktop__nav ul li a {
  color: #fff;
  border-bottom: 1px solid transparent;
  display: inline-block;
}
.main-header .menu-desktop__nav ul li a:hover {
  border-color: #fff;
  transition: border-color 0.5s;
}
.main-header .menu-desktop__nav ul li a.active {
  color: #d60069;
}
.main-header .menu-desktop__nav ul li#home-btn {
  position: relative;
  padding-left: 42px;
  transition: 0.3s;
}
@media only screen and (min-width: 1260px) {
  .main-header .menu-desktop__nav ul li#home-btn {
    padding-left: 61px;
  }
}
.main-header .menu-desktop__nav ul li#home-btn a {
  position: absolute;
  right: 0;
  width: 24.5px;
  height: 29px;
}
.main-header .menu-desktop__nav ul li#home-btn a span {
  position: relative;
}
.main-header .menu-desktop__nav ul li#home-btn a span .icon-home {
  position: absolute;
  top: -4px;
  left: 0;
}
.main-header.fixed-theme .menu-desktop {
  background-color: #000;
  padding: 5px 38px 5px 38px;
}
@media only screen and (min-width: 1260px) {
  .main-header.fixed-theme .menu-desktop {
    padding: 5px 76px 5px 76px;
  }
}
.main-header.fixed-theme .menu-desktop__logo {
  height: 75px;
  max-width: 70px;
}
.main-header.fixed-theme .menu-desktop #home-btn {
  padding-left: 0px;
}

.mouse-scroll {
  bottom: 60px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 25;
  cursor: pointer;
  transition: background-color 0.2s;
}
@media only screen and (min-width:0px) and (max-width:1024px) {
  .mouse-scroll {
    display: none;
  }
}
.mouse-scroll .mouse {
  width: 42px;
  height: 70px;
  border: 2px solid #000;
  border-radius: 20px;
  margin: 0 auto 10px auto;
}
.mouse-scroll .mouse:before {
  content: "";
  top: 20px;
  right: 0;
  left: 0;
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #000;
  border-radius: 50%;
  animation: scrolling 1.4s linear infinite;
  margin: 0 auto;
}
.mouse-scroll .mouse:after {
  content: "";
  right: 0;
  bottom: -35px;
  left: 0;
  position: absolute;
  display: block;
  width: 42px;
  height: 35px;
  background: url("/typo3conf/ext/cbf_template/Resources/Public/Frontend/assets/icon/arrows/arrow-dbl-down-black.svg") no-repeat center center;
  background-size: contain;
  animation: bounce 1.6s linear infinite;
}
.mouse-scroll .mouse:hover:before {
  background-color: #d60069;
}

.main-footer {
  font-family: "Geogrotesque-SemiBold", normal;
  letter-spacing: 2px;
  background-color: #000;
  color: #fff;
  padding: 19px;
}
.main-footer:after {
  content: "";
  display: block;
  clear: both;
}
@media only screen and (min-width:768px) {
  .main-footer {
    padding-left: 76px;
    padding-right: 76px;
  }
}
.main-footer a, .main-footer button {
  color: inherit;
  text-transform: uppercase;
  border-bottom: 1px solid transparent;
}
.main-footer a:hover, .main-footer button:hover {
  border-color: #fff;
  transition: border-color 0.5s;
}
.main-footer button {
  font-family: inherit;
  line-height: 1.5;
  letter-spacing: 2px;
}
.main-footer__meta {
  float: left;
}
.main-footer__meta li a {
  cursor: pointer;
}
@media only screen and (min-width:768px) {
  .main-footer__meta li {
    display: inline-block;
  }
  .main-footer__meta li:not(:last-of-type) {
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #fff;
  }
}
.main-footer__back-to-top {
  float: right;
}

.to-be-printed-agb {
  display: none;
}

.print-agb {
  margin: 1rem;
}
.print-agb section {
  padding: 4rem 0;
}
.print-agb .show {
  display: none;
}
.print-agb .print {
  display: block !important;
}

@media print {
  .print-agb .print {
    display: none !important;
  }
}
.contact-btns {
  text-align: center;
  margin-top: 19px;
  margin-bottom: 38px;
}
.contact-btns .contact-btn {
  position: relative;
  display: inline-block;
  font-size: 1.2rem;
  color: #000;
  letter-spacing: 1.5px;
  padding: 10px 45px;
  transition: all 0.3s;
  max-width: 100%;
}
@media only screen and (min-width:768px) {
  .contact-btns .contact-btn:last-of-type {
    padding-right: 0;
  }
}
.contact-btns .contact-btn:hover {
  padding-left: 65px;
  color: #d60069;
}
.contact-btns .contact-btn--phone {
  background: url("/typo3conf/ext/cbf_template/Resources/Public/Frontend/assets/icon/phone.svg") no-repeat left 4px/30px;
}
.contact-btns .contact-btn--mail {
  background: url("/typo3conf/ext/cbf_template/Resources/Public/Frontend/assets/icon/mail.svg") no-repeat left 10px/30px;
}

.modal-btn {
  position: relative;
  display: inline-block;
  line-height: 2rem;
  color: #d60069;
  padding: 0.25rem 0.4rem 0 2.4rem;
  transition: padding-left 0.3s;
}
.modal-btn.show {
  background: url("/typo3conf/ext/cbf_template/Resources/Public/Frontend/assets/icon/enlarge.svg") no-repeat left center/2rem 2rem;
}
.modal-btn.print {
  background: url("/typo3conf/ext/cbf_template/Resources/Public/Frontend/assets/icon/print-btn-bg.svg") no-repeat left center/2rem 2rem;
  display: none;
}
.modal-btn:hover {
  padding-left: 2.75rem;
}

.btn-wrap {
  text-align: center;
  margin-bottom: 1rem;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.4rem 1.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 600;
}
.btn-primary {
  color: #fff;
  background-color: #d60069;
  border-color: #d60069;
}
.btn-primary:hover, .btn-primary:active {
  outline: none;
  color: #fff;
  background-color: #a1004f;
  border-color: #a1004f;
}
.btn:focus, .btn:focus-visible, .btn:focus-within {
  outline: none;
  box-shadow: rgba(214, 0, 105, 0.5) 0px 0px 0px 0.2rem;
}

.lightbox-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media only screen and (min-width:768px) {
  .lightbox-wrap .col {
    width: 33.3333333333%;
  }
}
@media only screen and (min-width:1025px) {
  .lightbox-wrap .col {
    width: 20%;
  }
}

a.lightbox-box {
  display: block;
  position: relative;
  overflow: hidden;
}
a.lightbox-box:before {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  background: url("/typo3conf/ext/cbf_template/Resources/Public/Frontend/assets/icon/magnifier.svg") rgba(214, 0, 105, 0.75) no-repeat center/2.5rem 2.5rem;
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 0.4s, transform 0.4s;
}
a.lightbox-box:hover:before {
  opacity: 1;
  transform: scale(1);
}

.product-categories:after {
  content: "";
  display: block;
  clear: both;
}
@media only screen and (min-width:768px) {
  .product-categories .product-category-box {
    float: left;
    width: 33.3333%;
  }
}
.product-categories__headline {
  margin: 0 auto;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 38px;
}
.product-categories__headline h2 {
  font-size: 1.25rem;
}

.product-category-box {
  position: relative;
  display: block;
  color: inherit;
}
.product-category-box:after {
  content: "";
  right: 0;
  bottom: -10px;
  left: 0;
  position: absolute;
  margin: 0 auto;
  display: block;
  width: 32px;
  height: 32px;
  background: url("/typo3conf/ext/cbf_template/Resources/Public/Frontend/assets/misc/plus_white.svg") #d60069 no-repeat center/16px 16px;
  border-radius: 50%;
  transition: transform 0.3s;
}
.product-category-box:hover img {
  animation: swing 1.2s;
}
.product-category-box:hover:after {
  transform: rotate(90deg);
}
.product-category-box img {
  transition: transform 0.5s;
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.product-category-box__content {
  position: relative;
  z-index: 1;
  padding: 19px;
}
.product-category-box__headline {
  font-family: "GeogrotesqueXComp-SmBd", normal;
  font-size: 3rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 19px;
  margin-bottom: 10px;
}

.product-box:after {
  content: "";
  display: block;
  clear: both;
}
@media only screen and (min-width:768px) {
  .product-box:nth-of-type(1) {
    width: 35%;
  }
  .product-box:nth-of-type(2) {
    width: 30%;
  }
  .product-box:nth-of-type(3) {
    width: 35%;
  }
  .product-box:nth-of-type(3) .product-box__content {
    margin-left: 0;
  }
}
@media only screen and (max-width: 1239px) {
  .product-box:nth-of-type(3) ul {
    line-height: 1.3;
  }
  .product-box:nth-of-type(3) ul li span:after {
    content: "-";
  }
}
.product-box__content {
  color: #d60069;
  text-align: center;
  text-transform: uppercase;
  font-family: "Geogrotesque-SemiBold", normal;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  margin-top: 38px;
}
@media only screen and (min-width:768px) {
  .product-box__img {
    float: left;
  }
  .product-box__content {
    float: left;
    text-align: left;
    margin-left: 38px;
  }
}
@media only screen and (min-width:768px) and (min-width:768px) {
  .product-box__content br {
    display: none;
  }
}
@media only screen and (min-width:768px) and (max-width: 1239px) {
  .product-box__content br {
    display: block;
  }
}

.accordion__box {
  position: relative;
  background: transparent;
  border-bottom: 1px solid #000;
  padding: 2rem 19px 1.5rem 2rem;
  transition: background-color 0.3s;
  cursor: pointer;
}
.accordion__box--btn {
  color: #fff;
  top: 1.5rem;
  right: 38px;
  position: absolute;
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #d60069;
  border: 1px solid transparent;
  transition: all 0.4s;
}
.accordion__box--btn:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  border-left: 1px solid #fff;
  z-index: 1010;
  height: 12px;
  transition: all 0.4s;
}
.accordion__box--btn:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  border-bottom: 1px solid #fff;
  z-index: 1020;
  width: 12px;
  transition: all 0.4s;
}
.accordion__box:hover {
  background-color: #f2f2f2;
}
.accordion__box.is-open .accordion__box--btn {
  color: #d60069;
  background: #fff;
  border-color: #d60069;
}
.accordion__box.is-open .accordion__box--btn:before {
  border-color: transparent;
}
.accordion__box.is-open .accordion__box--btn:after {
  border-color: #d60069;
}
.accordion__headline {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #d60069;
  margin-bottom: 0;
}
.accordion__content {
  margin-top: 1.5rem;
}
.accordion__content ul {
  margin-bottom: 2rem;
}
.accordion__content ul:last-of-type {
  margin-bottom: 0;
}
.accordion__content ul li:first-of-type {
  font-weight: 600;
}
.accordion__content p {
  margin-bottom: 2rem;
}
.accordion__content p:last-of-type {
  margin-bottom: 0;
}
.accordion__content p img {
  margin: 19px 38px 19px 0;
  border-bottom: 5px solid transparent;
  padding-bottom: 10px;
  transition: border-bottom 0.3s;
}
.accordion__content p img:hover {
  border-bottom: 5px solid #d60069;
}

.headvisual {
  display: none;
  min-height: 250px;
  background: radial-gradient(ellipse at center, #fff 0%, #d2d2d2 100%);
}
@media only screen and (min-width:768px) {
  .headvisual {
    display: block;
  }
}

.owl-crsl--home {
  background: radial-gradient(ellipse at center, #fff 0%, #d2d2d2 100%);
}
@media only screen and (min-width:768px) {
  .owl-crsl--home .owl-crsl__slide {
    height: 100vh;
  }
}
@media only screen and (min-width:768px) {
  .owl-crsl--std .owl-crsl__slide {
    height: 75vh;
  }
}
.owl-crsl--facts__slide .table {
  width: 100%;
  max-width: 1000px;
  height: auto;
  text-align: center;
  display: table;
  margin: 0 auto;
}
@media only screen and (min-width: 1279px) {
  .owl-crsl--facts__slide .cell {
    display: table-cell;
    vertical-align: middle;
  }
}
.owl-crsl--facts__slide .cell p {
  font-size: 1.25rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  font-family: "Geogrotesque-SemiBold", normal;
  padding-left: 19px;
  padding-right: 19px;
}
@media only screen and (min-width: 1279px) {
  .owl-crsl--facts__slide .cell p {
    text-align: left;
    font-size: 2rem;
    line-height: 2.4rem;
    padding-left: 76px;
    padding-right: 0;
  }
}
.owl-crsl--facts__slide .cell .num {
  font-family: "GeogrotesqueXComp-SmBd", normal;
  font-size: 10rem;
  line-height: 1;
}
@media only screen and (min-width:768px) {
  .owl-crsl--facts__slide .cell .num {
    font-size: 15rem;
  }
}
.owl-crsl__slide {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 250px;
}
.owl-crsl__slide__headline {
  top: 100px;
  left: 38px;
  position: absolute;
  text-transform: uppercase;
  font-family: "GeogrotesqueXComp-SmBd", normal;
  font-size: 2.75rem;
  letter-spacing: 1px;
}
.owl-crsl__slide__headline:first-line {
  color: #d60069;
}
@media only screen and (min-width: 768px) {
  .owl-crsl__slide__headline {
    top: 45%;
    left: 76px;
    position: absolute;
    font-size: 5rem;
  }
}
@media only screen and (min-width: 1023px) {
  .owl-crsl__slide__headline {
    top: 45%;
    left: 76px;
    position: absolute;
    font-size: 5.5rem;
  }
}
@media only screen and (min-width: 1279px) {
  .owl-crsl__slide__headline {
    top: 45%;
    left: 76px;
    position: absolute;
    font-size: 7rem;
  }
}

.sticker {
  top: 37rem;
  right: 12rem;
  position: absolute;
  z-index: 1010;
  width: 11rem;
  height: 11rem;
  text-align: center;
  text-transform: uppercase;
  background-color: #d60069;
  color: #fff;
  border: 0.5rem solid #fff;
  border-radius: 50%;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);
  transform: translateY(-2.5rem) rotate(10deg);
  transition: all 0.4s;
  cursor: pointer;
  padding: 20px;
  margin: 0 auto -1rem auto;
}
.sticker:hover {
  top: 35.5rem;
  background-color: #d80d71;
}
.sticker__content {
  top: 50%;
  right: 0;
  left: 0;
  position: absolute;
  transform: translateY(-50%);
  font-size: 1.3rem;
  line-height: 1.75rem;
}
.sticker__content strong {
  display: block;
}

.lightbox {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 30;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
}
.lightbox__overlay {
  top: 0;
  left: 0;
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: rgba(0, 0, 0, 0.6);
}
.lightbox__cross {
  top: 2rem;
  right: 2rem;
  position: absolute;
  width: 30px;
  height: 30px;
  background: linear-gradient(to bottom, transparent 40%, #fff 40%, #fff 60%, transparent 60%), linear-gradient(to right, transparent 40%, #fff 40%, #fff 60%, transparent 60%);
  transform: rotate(45deg);
  cursor: pointer;
}
.lightbox__cross:hover {
  background: linear-gradient(to bottom, transparent 40%, #d60069 40%, #d60069 60%, transparent 60%), linear-gradient(to right, transparent 40%, #d60069 40%, #d60069 60%, transparent 60%);
}
.lightbox__prev, .lightbox__next {
  width: 3rem;
  height: 3rem;
  top: 50%;
  position: absolute;
  z-index: 1;
  transform: translateY(-50%);
  cursor: pointer;
  user-select: none;
  background: url("/typo3conf/ext/cbf_template/Resources/Public/Frontend/assets/icon/arrows/arrow-right-black.svg") no-repeat center;
}
.lightbox__prev:hover, .lightbox__next:hover {
  opacity: 0.8;
}
.lightbox__prev {
  left: 1rem;
  transform: scaleX(-1);
}
@media only screen and (min-width:768px) {
  .lightbox__prev {
    left: 38px;
  }
}
.lightbox__next {
  right: 1rem;
}
@media only screen and (min-width:768px) {
  .lightbox__next {
    right: 38px;
  }
}
.lightbox__img {
  top: 55%;
  left: 50%;
  position: absolute;
  text-align: center;
  opacity: 0;
  padding: 0 19px;
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.4s;
}
.lightbox__img img {
  border: 6px solid #fff;
}
.lightbox--is-visible {
  visibility: visible;
  opacity: 1;
}
.lightbox--is-visible .lightbox__img {
  top: 50%;
  opacity: 1;
  transition-delay: 0.2s;
}

.owl-crsl {
  position: relative;
  z-index: 1;
  display: none;
  width: 100%;
}
.owl-crsl .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.owl-crsl .owl-stage:after {
  content: "";
  display: block;
  height: 0;
  line-height: 0;
  clear: both;
  visibility: hidden;
}
.owl-crsl .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-crsl .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-crsl .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}
.owl-crsl .owl-nav.disabled,
.owl-crsl .owl-dots.disabled {
  display: none;
}
.owl-crsl .owl-nav .owl-prev,
.owl-crsl .owl-nav .owl-next,
.owl-crsl .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-crsl.owl-loaded {
  display: block;
}
.owl-crsl.owl-loading {
  opacity: 0;
  display: block;
}
.owl-crsl.owl-hidden {
  opacity: 0;
}
.owl-crsl.owl-refresh .owl-item {
  display: none;
}
.owl-crsl.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-crsl.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-crsl--buttons .owl-prev, .owl-crsl--buttons .owl-next {
  top: 50%;
  position: absolute;
  width: 2rem;
  height: 2rem;
  background: url("/typo3conf/ext/cbf_template/Resources/Public/Frontend/assets/icon/arrows/arrow-right-black.svg") no-repeat;
  background-size: contain;
  transform: translateY(-50%);
  transition: background-color 0.3s;
}
.owl-crsl--buttons .owl-prev {
  left: 19px;
  transform: translateY(-50%) scaleX(-1);
}
@media only screen and (min-width:768px) {
  .owl-crsl--buttons .owl-prev {
    left: 76px;
  }
}
.owl-crsl--buttons .owl-next {
  right: 19px;
}
@media only screen and (min-width:768px) {
  .owl-crsl--buttons .owl-next {
    right: 76px;
  }
}
.owl-crsl--dots .owl-dots {
  right: 0;
  bottom: -50px;
  left: 0;
  position: absolute;
  text-align: center;
}
.owl-crsl--dots .owl-dots .owl-dot {
  display: inline-block;
}
.owl-crsl--dots .owl-dots .owl-dot:not(:last-of-type) {
  margin-right: 1rem;
}
.owl-crsl--dots .owl-dots .owl-dot span {
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  transition: background-color 0.3s;
}
.owl-crsl--dots .owl-dots .owl-dot:hover span, .owl-crsl--dots .owl-dots .owl-dot.active span {
  background-color: #d60069;
}

.owl-crsl .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-crsl .owl-animated-in {
  z-index: 0;
}
.owl-crsl .owl-animated-out {
  z-index: 1;
}
.owl-crsl .fadeOut {
  animation-name: fadeOut;
}
.owl-crsl .fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-crsl .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}
.owl-crsl .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.frame-type-cbftemplate_factsslider .owsl-nav {
  display: none;
}
@media only screen and (min-width:768px) {
  .frame-type-cbftemplate_factsslider .owsl-nav {
    display: block;
  }
}

.tab-btns {
  text-align: center;
  margin-bottom: 19px;
}
.tab-btns li {
  display: inline-block;
  font-family: "Geogrotesque-SemiBold", normal;
  font-size: 1.3rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #d60069;
  cursor: pointer;
  margin: 0 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s;
}
.tab-btns li:hover, .tab-btns li.active {
  border-bottom: 2px solid #000;
}

.tab-container {
  position: relative;
  width: 100%;
  min-height: 275px;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 19px;
}
.tab-container p {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 38px;
  max-width: 950px;
}
.tab-container__stage:after {
  content: "";
  display: block;
  clear: both;
}
.tab-container__tab {
  float: left;
}
.tab-container__image {
  height: 275px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
@media only screen and (min-width:768px) {
  .tab-container__image {
    height: 750px;
  }
}
.tab-container__controls {
  top: 0;
  position: absolute;
  width: 100%;
  height: inherit;
}
.tab-container__prev, .tab-container__next {
  top: 67%;
  position: relative;
  width: 2rem;
  height: 2rem;
  background: url("/typo3conf/ext/cbf_template/Resources/Public/Frontend/assets/icon/arrows/arrow-right-black.svg") no-repeat center center;
  background-size: contain;
  cursor: pointer;
}
@media only screen and (min-width:768px) {
  .tab-container__prev, .tab-container__next {
    top: 50%;
    position: relative;
  }
}
.tab-container__prev {
  position: absolute;
  left: 19px;
  transform: scaleX(-1);
}
@media only screen and (min-width:768px) {
  .tab-container__prev {
    left: 76px;
  }
}
.tab-container__next {
  position: absolute;
  right: 19px;
}
@media only screen and (min-width:768px) {
  .tab-container__next {
    right: 76px;
  }
}

.reveal-modal {
  z-index: 35;
  visibility: hidden;
  opacity: 0;
}
.reveal-modal.active {
  top: 0;
  visibility: visible;
  opacity: 1;
}
.reveal-modal__content {
  top: -100%;
  left: 0;
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  border-left: 3px solid #d60069;
  border-right: 3px solid #d60069;
  padding: 2rem;
  padding-top: 40px;
  transition: all 0.6s cubic-bezier(0.42, 0, 0.58, 1);
  overflow: scroll;
  height: 100%;
}
.reveal-modal__overlay {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 30;
  opacity: 0;
  visibility: hidden;
}
.reveal-modal__overlay .reveal-modal__bg {
  width: inherit;
  height: inherit;
  background: rgba(0, 0, 0, 0.6);
}
.reveal-modal__overlay.active {
  opacity: 1;
  visibility: visible;
}
.reveal-modal .section {
  padding: 4rem 0;
}

.reveal-modal__cross {
  top: 2rem;
  right: 2rem;
  position: fixed;
  width: 30px;
  height: 30px;
  background: linear-gradient(to bottom, transparent 40%, #fff 40%, #fff 60%, transparent 60%), linear-gradient(to right, transparent 40%, #fff 40%, #fff 60%, transparent 60%);
  transform: rotate(45deg);
  cursor: pointer;
  z-index: 35;
  opacity: 0;
  visibility: hidden;
}
.reveal-modal__cross:hover {
  background: linear-gradient(to bottom, transparent 40%, #d60069 40%, #d60069 60%, transparent 60%), linear-gradient(to right, transparent 40%, #d60069 40%, #d60069 60%, transparent 60%);
}
.reveal-modal__cross.active {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .reveal-modal__content {
    height: 100%;
    overflow: scroll;
  }

  .reveal-modal__cross {
    transition: transform 0.3s;
    background: linear-gradient(to bottom, transparent 40%, #d60069 40%, #d60069 60%, transparent 60%), linear-gradient(to right, transparent 40%, #d60069 40%, #d60069 60%, transparent 60%);
  }
  .reveal-modal__cross:hover {
    transform: rotate(90deg);
  }
}
@media only screen and (max-height: 700px) {
  .reveal-modal__content {
    height: 100%;
    overflow: scroll;
  }
}
@media only screen and (min-width: 768px) and (min-height: 700px) {
  .reveal-modal__content {
    left: 50%;
    width: 80%;
    max-width: 800px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    margin: 0 auto;
  }
  .reveal-modal__content.active {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (min-width: 1080px) and (min-height: 700px) {
  .reveal-modal__content {
    width: auto;
  }
}
.cookie-message {
  position: fixed;
  top: 200px;
  left: 0;
  width: 270px;
  z-index: 100;
  background-color: #FFF;
  color: #000;
  padding: 40px 0;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}
.cookie-message__container {
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}
.cookie-message__text {
  line-height: 1.8;
  max-width: 900px;
}
.cookie-message__info-btn {
  display: block;
  margin-top: 6px;
  color: #000;
  border-bottom: 1px solid #000;
  cursor: pointer;
}
.cookie-message__dismiss-btn {
  display: block;
  margin-top: 24px;
}

@font-face {
  font-family: "Geogrotesque-Light";
  src: url("../../assets/fonts/311B1F_0_0.eot");
  src: url("../../assets/fonts/311B1F_0_0.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/311B1F_0_0.woff2") format("woff2"), url("../../assets/fonts/311B1F_0_0.woff") format("woff"), url("../../assets/fonts/311B1F_0_0.ttf") format("truetype");
}
@font-face {
  font-family: "Geogrotesque-SemiBold";
  src: url("../../assets/fonts/311B1F_1_0.eot");
  src: url("../../assets/fonts/311B1F_1_0.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/311B1F_1_0.woff2") format("woff2"), url("../../assets/fonts/311B1F_1_0.woff") format("woff"), url("../../assets/fonts/311B1F_1_0.ttf") format("truetype");
}
@font-face {
  font-family: "GeogrotesqueXComp-SmBd";
  src: url("../../assets/fonts/311B1F_2_0.eot");
  src: url("../../assets/fonts/311B1F_2_0.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/311B1F_2_0.woff2") format("woff2"), url("../../assets/fonts/311B1F_2_0.woff") format("woff"), url("../../assets/fonts/311B1F_2_0.ttf") format("truetype");
}
.tx-powermail {
  max-width: 800px;
  margin: 0 auto;
}
.tx-powermail legend {
  display: none;
}
.tx-powermail .powermail_fieldset {
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.tx-powermail .powermail_fieldset .powermail_field {
  margin: 0 9.5px 19px 9.5px;
}
.tx-powermail .powermail_fieldset textarea {
  min-width: 100%;
  max-width: 100%;
}
.tx-powermail .powermail_fieldset input {
  width: 100%;
}
.tx-powermail .powermail_fieldset input, .tx-powermail .powermail_fieldset textarea, .tx-powermail .powermail_fieldset .dragzone-position {
  padding: 9.5px;
  border: 1px solid #d60069;
  border-radius: 4px;
}
.tx-powermail .powermail_fieldset input::placeholder, .tx-powermail .powermail_fieldset textarea::placeholder, .tx-powermail .powermail_fieldset .dragzone-position::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.5);
}
.tx-powermail .powermail_fieldset input:-ms-input-placeholder, .tx-powermail .powermail_fieldset textarea:-ms-input-placeholder, .tx-powermail .powermail_fieldset .dragzone-position:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.5);
}
.tx-powermail .powermail_fieldset input::-ms-input-placeholder, .tx-powermail .powermail_fieldset textarea::-ms-input-placeholder, .tx-powermail .powermail_fieldset .dragzone-position::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.5);
}
.tx-powermail .powermail_fieldset .powermail_fieldwrap_type_check .checkbox {
  display: flex;
  position: relative;
}
.tx-powermail .powermail_fieldset .powermail_fieldwrap_type_check .checkbox:not(:first-child) {
  margin-top: 5px;
}
.tx-powermail .powermail_fieldset .powermail_fieldwrap_type_check .checkbox .powermail_checkbox {
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
  top: 8.5px;
  left: 8.5px;
}
@media only screen and (min-width:768px) {
  .tx-powermail .powermail_fieldset .powermail_fieldwrap_type_check .checkbox .powermail_checkbox {
    top: 9.5px;
    left: 9.5px;
  }
}
@media only screen and (min-width:1025px) {
  .tx-powermail .powermail_fieldset .powermail_fieldwrap_type_check .checkbox .powermail_checkbox {
    top: 11px;
    left: 11px;
  }
}
.tx-powermail .powermail_fieldset .powermail_fieldwrap_type_check .checkbox label {
  text-align: left;
  padding-left: 38px;
  position: relative;
}
.tx-powermail .powermail_fieldset .powermail_fieldwrap_type_check .checkbox label:before {
  content: "";
  width: 17px;
  height: 17px;
  border: 1px solid #d60069;
  border-radius: 4px;
  background-color: #fff;
  transition: 0.3s;
  position: absolute;
  top: -1px;
  left: 0;
}
@media only screen and (min-width:768px) {
  .tx-powermail .powermail_fieldset .powermail_fieldwrap_type_check .checkbox label:before {
    width: 19px;
    height: 19px;
  }
}
@media only screen and (min-width:1025px) {
  .tx-powermail .powermail_fieldset .powermail_fieldwrap_type_check .checkbox label:before {
    width: 22px;
    height: 22px;
  }
}
.tx-powermail .powermail_fieldset .powermail_fieldwrap_type_check .checkbox label.checked:before {
  background-color: #d60069;
}
.tx-powermail .powermail_fieldset .layout1 {
  width: 50%;
}
.tx-powermail .powermail_fieldset .layout2 {
  width: 100%;
}
.tx-powermail .powermail_fieldset #file-upload-button {
  background-color: #d60069;
}
.tx-powermail .powermail_fieldwrap_rueckruf .powermail_field {
  margin-bottom: 5px;
}
.tx-powermail .powermail_fieldwrap_mandatory {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 19px;
}
.tx-powermail .powermail_fieldwrap_mandatory .powermail_field {
  margin: 0;
}
.tx-powermail .powermail_fieldwrap_type_multiupload {
  text-align: left;
}
.tx-powermail .powermail_fieldwrap_type_multiupload .dropzone-area {
  max-width: 70%;
}
@media only screen and (min-width:768px) {
  .tx-powermail .powermail_fieldwrap_type_multiupload .dropzone-area {
    max-width: 50%;
  }
}
.tx-powermail .powermail_fieldwrap_type_multiupload .dropzone-area .dragzone-position {
  width: 100%;
  height: 120px;
  border-style: dotted;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.5);
}
.tx-powermail .powermail_fieldwrap_type_multiupload .add {
  margin: 19px 19px 19px 0;
  display: inline-block;
  cursor: pointer;
}
.tx-powermail .powermail_fieldwrap_type_multiupload #tpl {
  display: none;
}
.tx-powermail .powermail_fieldwrap_type_multiupload .dz-details {
  position: relative;
}
.tx-powermail .powermail_fieldwrap_type_multiupload .dz-details div {
  display: inline-block;
  padding-left: 19px;
}
.tx-powermail .powermail_fieldwrap_type_multiupload .dz-details .dz-remove {
  padding-left: 0;
  padding-right: 9.5px;
  cursor: pointer;
}
.tx-powermail .powermail_fieldwrap_type_multiupload .dz-details .dz-remove span {
  width: 15.5px;
  height: 15.5px;
  border: 1px solid #d60069;
  border-radius: 7.5px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.tx-powermail .powermail_fieldwrap_type_multiupload .dz-details .dz-remove span:before, .tx-powermail .powermail_fieldwrap_type_multiupload .dz-details .dz-remove span:after {
  content: "";
  border-left: 1px solid #d60069;
  height: 10px;
  width: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.tx-powermail .powermail_fieldwrap_type_multiupload .dz-details .dz-remove span:before {
  transform: translate3d(-50%, -50%, 0) rotate(45deg);
}
.tx-powermail .powermail_fieldwrap_type_multiupload .dz-details .dz-remove span:after {
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
.tx-powermail .powermail_button {
  min-width: 150px;
  text-align: center;
  padding: 9.5px;
  border: 1px solid #d60069;
  border-radius: 4px;
  background-color: transparent;
  transition: 0.3s;
}
.tx-powermail .powermail_button:hover {
  background-color: #d60069;
  color: #fff;
}
.tx-powermail .powermail_label {
  display: none;
}

@font-face {
  font-family: "cbf-icons";
  font-display: swap;
  src: url("../icon-font/cbf-icons.eot");
  src: url("../icon-font/cbf-icons.eot?#iefix") format("eot"), url("../icon-font/cbf-icons.woff2") format("woff2"), url("../icon-font/cbf-icons.woff") format("woff"), url("../icon-font/cbf-icons.ttf") format("truetype"), url("../icon-font/cbf-icons.svg#cbf-icons") format("svg");
}
.icon-arrow:before {
  font-family: "cbf-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  content: "\e001";
}

.icon-home:before {
  font-family: "cbf-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  content: "\e002";
}

.icon-icon-clear:before {
  font-family: "cbf-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  content: "\e003";
}

.icon-three-arrows:before {
  font-family: "cbf-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  content: "\e004";
}

#lightbox {
  display: none;
  position: fixed;
  z-index: 1110;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.75s ease-in-out;
}
#lightbox-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  z-index: 1;
}
#lightbox .close-btn {
  position: fixed;
  top: 38px;
  right: 38px;
  width: 30px;
  height: 30px;
  transition: 0.3s;
  cursor: pointer;
  z-index: 1050;
}
#lightbox .close-btn:before, #lightbox .close-btn:after {
  content: "";
  position: absolute;
  height: 30px;
  top: 50%;
  left: 50%;
  border-left: 4px solid #b2b2b2;
  transition: 0.3s;
}
#lightbox .close-btn:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
#lightbox .close-btn:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
#lightbox .close-btn:hover {
  transform: rotate(90deg);
}
#lightbox .close-btn:hover:before, #lightbox .close-btn:hover:after {
  border-color: #fff;
}
#lightbox #loader-wrap {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#lightbox .loader,
#lightbox .loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
#lightbox .loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #d60069;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#lightbox .player-wrap {
  position: absolute;
  width: 100%;
  max-width: 1636px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, 0%, 0);
  transition: transform 0.75s ease-in-out;
  z-index: 3;
}
#lightbox .player-wrap:before {
  padding-top: 56.25%;
  content: "";
  display: block;
}
#lightbox #player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
@media only screen and (min-width:1025px) {
  #lightbox .close-btn {
    top: 76px;
    right: 76px;
  }
}

.open-lightbox {
  overflow: hidden;
}
.open-lightbox #lightbox {
  display: block;
  opacity: 1;
  pointer-events: auto;
}
.open-lightbox #lightbox .player-wrap {
  transform: translate3d(-50%, -50%, 0);
}

.video-box {
  background-color: #000;
  position: relative;
  margin: auto;
  max-width: 800px;
}
.video-box-item {
  position: relative;
  cursor: pointer;
}
.video-box-item img {
  width: 100%;
}
.video-box-item span:before {
  content: "";
  position: absolute;
  z-index: 1010;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  background: rgba(0, 0, 0, 0);
}
.video-box-item:before {
  content: "";
  position: absolute;
  z-index: 1020;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 80px;
  height: 80px;
  border: 8px solid #fff;
  border-radius: 40px;
  transition: 0.3s;
}
.video-box-item:after {
  content: "";
  position: absolute;
  z-index: 1030;
  top: 50%;
  left: 50%;
  transform: translate3d(-11px, -50%, 0);
  color: #d60069;
  transition: 0.3s;
  box-sizing: border-box;
  border-style: solid;
  border-width: 17px 0px 17px 29px;
  border-color: transparent transparent transparent #fff;
}
.video-box-item:hover:before {
  background: rgba(214, 0, 105, 0.7);
  border-color: #fff;
}
.video-box-item:hover:after {
  border-color: transparent transparent transparent #fff;
}
.video-box-item:hover span:before {
  background: rgba(0, 0, 0, 0.35);
}

.frame-default + .frame-type-cbftemplate_videobox {
  margin-top: 38px;
}